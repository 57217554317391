<template>
  <v-container>
    <v-row>
      <v-col class="py-0 my-0" lg="6" md="6" sm="12">
        <v-form class="frmRegistro" ref="frmRegistro">
          <span class="centrado">
            <h1>Comienza ya con</h1>
          </span>
          <img
            src="../../../public/img/login/logo-aco.png"
            alt="logo"
            max-width="100%"
          />
          <v-row>
            <v-col class="py-1 my-0" cols="12">
              <v-text-field
                name="name"
                label="Nombre Empresa"
                id="id"
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.trade_name"
              ></v-text-field>
            </v-col>
            <v-col class="py-1 my-0" cols="12">
              <b>Persona de contacto (Administrador del sistema) </b>
            </v-col>
            <v-col class="py-1 my-0" cols="4">
              <v-text-field
                name="name"
                label="Nombre Empresa"
                placeholder=""
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.names"
              ></v-text-field>
            </v-col>

            <v-col class="py-1 my-0" cols="4">
              <v-text-field
                name="name"
                label="Apellido Paterno*"
                placeholder=""
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.surname"
              ></v-text-field>
            </v-col>
            <v-col class="py-1 my-0" cols="4">
              <v-text-field
                name="name"
                label="Apellido Materno*"
                placeholder=""
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.second_surname"
              ></v-text-field>
            </v-col>
            <v-col class="py-1 my-0" cols="12">
              <v-text-field
                name="name"
                label="Email*"
                placeholder=""
                outlined
                dense
                :hide-details="!errorEmail"
                v-model="$store.state.enterprises.data.email"
                @focusout="validarCorreo()"
                :error-messages="errorEmail"
                id="Email"
              ></v-text-field>
            </v-col>
            <v-col>
              <CboPais
                @paisSeleccionado="
                  $store.state.enterprises.data.id_pais = $event
                "
                :outlined="true"
                :dense="true"
                :hideDetails="true"
              />
            </v-col>
            <v-col class="py-1 my-0" cols="12">
              <v-text-field
                name="name"
                label="Teléfono*"
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.phone"
              ></v-text-field>
            </v-col>
            <v-col class="py-1 my-0" cols="12">
              <v-btn color="info" v-if="enviarCodigo" @click="enviarCorreo()"
                >Enviar Código</v-btn
              >
              <p>
                Ingresa el código de 4 dígitos enviado a tu correo. El email
                puede tardar entre 1 y 2 minutos de llegar
              </p>
              <v-snackbar
                v-model="snackbarEmail"
                :multi-line="multiLine"
                :color="colorSnakBar"
                :timeout="timeout"
              >
                <v-icon class="mx-1">mdi-{{ icon }}</v-icon>
                {{ text }}
              </v-snackbar>
            </v-col>
            <v-col class="py-1 my-0" cols="8">
              <v-text-field
                name="name"
                label="Código de acceso*"
                outlined
                dense
                hide-details
                v-model="$store.state.enterprises.data.codigo"
              ></v-text-field>
            </v-col>
            <v-col class="py-1 my-0" cols="4" v-if="!reenviarCodigo">
              {{ minutes }}:{{ secondsFormatted }}
            </v-col>
            <v-col class="py-1 my-0" cols="12">
              <a @click="enviarCorreo()" v-if="!enviarCodigo && timeLeft <= 0"
                >Reenviar Código</a
              >
            </v-col>
            <v-col class="py-1 my-0 centrado" cols="12">
              <v-btn class="py-1" color="error" @click="crearNuevaEmpresa()"
                >Empezar prueba gratis de 30 días</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- v-show="$vuetify.breakpoint.lg" -->
      <v-col
        class="py-1 my-0 centrado Slider"
        cols="6"
      >
        <v-carousel
          v-model="model"
          :cycle="true"
          continuous
          :show-arrows="false"
          reverse-transition="fade-transition"
          transition="fade-transition"
          hide-delimiter-background
          class="px-0"
        >
          <v-carousel-item v-for="(text, i) in textCarrusel" :key="i">
            <v-sheet
              class="centrado sheetSlider transparent-background"
              height="100%"
              tile
            >
              <p>
                {{ text.text1 }}
              </p>

              <h1>{{ text.text2 }}</h1>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <p></p>

        <v-btn color="success">
          <v-icon class="mx-1">mdi-whatsapp</v-icon> Whatapp</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialogRegistrandoEmpresa" max-width="50%">
      <v-card class="py-5 px-5">
        <h1 class="py-5">Estamos preparando algunas cosas</h1>
        <v-progress-linear
          color="#4256BE"
          indeterminate
          rounded
          height="10"
        ></v-progress-linear>
        <h2 class="py-5">Esto no tardará mucho...</h2>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRegistro" scrollable max-width="40%">
      <v-card>
        <div class="centrado modalConfirm">
          <p>TU ACCESO AL SISTEMA HA SIDO CREADO CON ÉXITO</p>
          <v-icon color="success" :size="100" class="iconConfirm"
            >mdi-check-circle-outline</v-icon
          >
          <h2 class="red--text">
            *Hemos enviado tu usuario y contraseña a tu email.
          </h2>
          <p class="details">
            El email podría demorar entre 1 y 2 minutos en llegar
          </p>
          <v-btn color="info" @click="iniciarConfiguración()"> Continuar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CboPais from "@/components/comun/cboPais.vue";
import { mapActions } from "vuex";
export default {
  components: {
    CboPais,
  },
  data() {
    return {
      errorEmail: "",
      enviarCodigo: true,
      snackbarEmail: false,
      timeLeft: 3 * 60, // 5 minutos en segundos
      intervalId: null,
      dialogRegistrandoEmpresa: false,
      confirmRegistro: false,
      model: 0,
      id_pais: null,
      reenviarCodigo: true,
      text: "",
      colorSnakBar: "",
      icon: "",
      snackbarEmail: false,
      timeout: 2000,
      multiLine: true,
      textCarrusel: [
        {
          text1:
            "Conéctate donde quieras, cuando quieras, gestiona tu negocio globalmente, sin límites.1",
          text2: "¡EMPIEZA CON 30 DÍAS GRATIS!",
        },
        {
          text1:
            "Reportes financiero al instante: obten la información que necesitas con un solo clic",
          text2: "¡DESCUBRELO CON 30 DÍAS GRATIS",
        },
        {
          text1:
            "Centraliza toda tu empresa un solo sistema para todas tus operaciones y administración",
          text2: "¡PRUEBA NUESTRA PLATAFORMA HOY",
        },
        // { text1: "ddddddddddd", text2: "" },
      ],
    };
  },
  computed: {
    // Calcula los minutos restantes
    minutes() {
      return Math.floor(this.timeLeft / 60);
    },
    // Calcula los segundos restantes y formatea el valor (si es menor a 10, añade un 0 delante)
    secondsFormatted() {
      const seconds = this.timeLeft % 60;
      return seconds < 10 ? "0" + seconds : seconds;
    },
  },
  mounted() {
    // Inicia el temporizador cuando el componente es montado
    // this.startTimer();
  },
  methods: {
    ...mapActions([
      // "_getPais",
      "RegistroNuevaEmpresa",
      "enviarCorreoValidacion",
      "validarCodigoVerificacion",
      "_getMenuItem",
      "validarCorreoRegistro",
    ]),
    async validarCorreo() {
      this.errorEmail = "";
      let res = await this.validarCorreoRegistro();
      if (res.estadoflag) {
        this.errorEmail = res.mensaje;
        document.getElementById("Email").focus();
      }
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.reenviarCodigo = false;
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async iniciarConfiguración() {
      await this._getMenuItem();
      this.$router.push({ name: "ConfiguracionInicial" }, () => {});
    },
    async enviarCorreo() {
      this.timeLeft = 3 * 60;
      this.startTimer();
      let val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        this.$store.state.enterprises.data.email
      );
      if (val) {
        this.loading = true;
        this.loadingRegistro = true;
        this.enviarCorreoValidacion().catch((err) => {
          console.log(err);
        });
        this.reenviarCodigo = false;
        this.enviarCodigo = false;
        this.startTimer();
      } else {
        this.snackbarEmail = !val;
        this.colorSnakBar = "orange darken-2";
        this.text = "Ingrese un correo valido.";
        this.icon = "alert";
      }
    },
    async crearNuevaEmpresa() {
      this.validatedCodigo = "";
      if (this.$refs.frmRegistro.validate()) {
        let res = await this.validarCodigoVerificacion(true);
        if (res.estadoflag) {
          this.dialogRegistrandoEmpresa = true;

          let res = await this.RegistroNuevaEmpresa();

          this.dialogRegistrandoEmpresa = false;

          this.confirmRegistro = true;
        } else {
          this.validatedCodigo = res.mensaje;
          this.snackbarEmail = true;
          this.colorSnakBar = "orange darken-2";
          this.text = res.mensaje;
          this.icon = "alert";
        }
      }
    },
  },
  beforeDestroy() {
    // Asegúrate de limpiar el intervalo si el componente es destruido
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.centrado {
  align-content: center;
  text-align: center;
}
.frmRegistro {
  /* border: 1px solid red; */
  padding: 0 5%;
  box-shadow: inset;
}
.Slider {
  color: #fff;
  background-image: url("../../../public/img/login/Slider.png");
  /* border: 1px solid red; */
  padding: 0 5%;
}
.modalConfirm {
  padding: 20px 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.details {
  font-size: 1rem;
}
.sheetSlider {
  padding: 0 50px;
  color: #fff;
}
.transparent-background {
  background-color: transparent !important;
}
@media (max-width: 1000px) {
  img {
    width: 350px;
  }
  .ocultar{
    /*visibility: hidden;*/
  }
}
</style>
